//

uniform vec2 uMouse;
uniform float uIntensity;
uniform sampler2D sampler;
uniform vec2 resolution;
uniform float uTime;

void main() {
    vec2 uv = gl_FragCoord.xy / resolution;

    // Apply mouse interaction
    vec2 distortedUV = uv + uMouse * uIntensity;

    // Calculate deformation based on time and UV coordinates
    float deformation = sin(uTime * 0.5 + distortedUV.x * 15.0) * 0.1 / uIntensity; 
    float skewX = cos(uTime * 0.5) * 0.1; 
    float skewY = cos(uTime * 0.5) * 0.1; 
    float skewZ = sin(uTime * 0.5) * 0.1;
    
    
    vec2 deformedUV = vec2(distortedUV.x, distortedUV.y + skewZ + deformation + skewX + skewY);

    
    vec3 textureColor = texture(sampler, deformedUV).rgb;

    gl_FragColor = vec4(textureColor, 1.0);
}
