uniform vec2 uMouse;
uniform float uIntensity;
uniform sampler2D noiseTexture;
uniform float uTime;

void main() {
    vec3 pos = position;

    // Calculate fluid distortion based on noise and time
    vec2 noiseCoords = (pos.xz + uTime * 0.1) * 0.1;
    float noiseFactor = texture(noiseTexture, noiseCoords).r * 0.1;

    vec3 distortion = vec3(
        sin(uTime + pos.y * 10.0) * noiseFactor,
        cos(uTime + pos.x * 10.0) * noiseFactor,
        0.0
    );

    pos += distortion * uIntensity;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
}
